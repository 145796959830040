// js for takken-timber-boots
// studiotakken.nl


import 'bootstrap';

jQuery(document).ready(function ($) {


  function is_touch_device() {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }
  // check touch support
  var supportsTouch = is_touch_device();

  if (supportsTouch == true) {
    $('body').addClass("touch-device");
  }

  /* Go to parent link of a dropdown menu if clicked when dropdown menu is open */
  $('.dropdown a.dropdown-toggle, a.dropdown-submenu').on('click', function () {
    if (!supportsTouch) {
      // standard desktop
      location.assign($(this).attr('href'));
    } else {
      // on touch devices
      if ($(this).attr('aria-expanded') === "true") {
        location.assign($(this).attr('href'));
      }
    }
  });

  // Smooth scrolling for anchor links
  function smoothScroll(target, offset) {
    $('html, body').animate({
      scrollTop: target.offset().top - offset
    }, 1000, function () {
      var $target = $(target);
      $target.focus();
      if ($target.is(":focus")) {
        return false;
      }
    });
  }

  // Select all links with hashes
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {

        // navbar height
        var main_nav_height = $('#main-nav-bar').height();

        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {

          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          smoothScroll(target, main_nav_height + 20);
        }
      }
    });


  $(".se-pre-con").fadeOut("slow");

  $('.internelink').on('click', function (event) {
    const link = $(this).find('a').first();
    if (link.length) {
      const href = link.attr('href');
      if (href && href.indexOf('#') >= 0) {
        event.preventDefault();
        const main_nav_height = $('#main-nav-bar').height();
        const target = $(href);
        if (target.length) {
          smoothScroll(target, main_nav_height + 20);
        } else {
          window.location = href;
        }
      } else {
        window.location = href;
      }
    }
  });


  // Loop through each circle and assign random animation delay and duration for unequal breathing effects
  document.querySelectorAll('svg circle').forEach(circle => {
    const delay = Math.random(); // random delay between 0s and 1s
    const duration = 2.8 + Math.random() * 0.4; // random duration between 2.8s and 3.2s
    circle.style.animationDelay = delay + 's';
    circle.style.animationDuration = duration + 's';
  });
});

